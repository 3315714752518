import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Constants from "../../Domain/constants";
import Selectors from "../../Selectors";
import Login from "../../Components/Login";
import * as Widget2 from "../../Components/Widget2";
import ActionCreator from "../../ActionCreator";

const UiState = {
  CREATE_ORDER: "CREATE_ORDER",
  CHECKOUT: "CHECKOUT"
};

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.CREATE_ORDER,
      order: null,
      createOrderData: {
        paymentType: "nccc",
        currency: "TWD",
        title: "default title"
      },
      error: ""
    };
  }

  render() {
    let { profile } = this.props;
    let { uiState } = this.state;
    if (!profile) {
      return (
        <Widget2.MaxWidth width={800} css="padding-top: 50px;">
          <Login.Button />
        </Widget2.MaxWidth>
      );
    }
    return (
      <Widget2.MaxWidth width={800} css="padding-top: 50px;">
        <Widget2.Center>
          <h2 style={{ marginBottom: 10 }}>結帳</h2>
          {(() => {
            switch (uiState) {
              case UiState.CREATE_ORDER:
                return this._renderCreateOrder();
              case UiState.CHECKOUT:
                return this._renderCheckout();
              default:
                return this._renderCreateOrder();
            }
          })()}
        </Widget2.Center>
      </Widget2.MaxWidth>
    );
  }

  _createOrder = () => {
    let { appActions } = this.props;
    let { createOrderData } = this.state;
    if (!createOrderData.title) {
      this.setState({ error: "訂單名稱不可為空！" });
      return;
    }
    appActions
      .createOrder({ ...createOrderData })
      .then(order => this.setState({ order, uiState: UiState.CHECKOUT }))
      .catch(err => this.setState({ error: "發生錯誤！" }));
  };

  _renderCreateOrder = () => {
    let { createOrderData, error } = this.state;
    return (
      <Widget2.Center>
        <Form css="margin-top: 20px;">
          <Widget2.FormField>
            <label>付款方式</label>
            <select
              value={createOrderData.paymentType}
              onChange={e =>
                this.setState({
                  createOrderData: {
                    ...createOrderData,
                    paymentType: e.target.value
                  }
                })
              }
            >
              <option value="ecpay">ECPAY</option>
              <option value="nccc">NCCC</option>
            </select>
          </Widget2.FormField>

          <Widget2.FormField>
            <label>訂單名稱</label>
            <input
              value={createOrderData.title}
              onChange={e =>
                this.setState({
                  createOrderData: {
                    ...createOrderData,
                    title: e.target.value
                  }
                })
              }
            />
          </Widget2.FormField>
        </Form>

        {error && <div style={{ color: "red" }}>{error}</div>}

        <Widget2.Button
          css="margin-top: 10px;"
          onClick={() => this._createOrder()}
        >
          成立訂單
        </Widget2.Button>
      </Widget2.Center>
    );
  };

  _renderCheckout = () => {
    let { order } = this.state;
    return (
      <a
        href={`${Constants.apiUrl}/checkout/request/?order=${
          order.id
        }&token=${window.localStorage.getItem("token")}`}
      >
        PAY
      </a>
    );
  };
}

const Form = styled.div`
  ${props => props.css || ""};
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(CheckoutPage)
);
